<template>
    <section class="my-orders page-content">
        <order-filter
            :selected="searchObject.preset"
            :search-object="searchObject"
            @filterOrder="onFilterOrder"
            @filterByStatus="onFilterOrder"
            @presetCancel="setPreset"
        />

        <div class="wrapper wrapper--order-filter">
            <PresetsFilter
                v-if="counter"
                :presets="presets"
                :selected="searchObject.preset"
                :loading="isLoadingPresets"
                @setPreset="setPreset"
            >
                <template #rightSide>
                    <span
                        v-if="!isLoading"
                        class="count"
                    >
                        {{ counterMoney | money }}
                    </span>
                    <template v-else>
                        <div class="preset-filter_loader preset-filter_loader--order-list">
                            <PresetLoader />
                        </div>
                    </template>
                </template>
            </PresetsFilter>
            <PresetsFilterSelect
                v-if="counter"
                v-model="searchObject.preset"
                :counter-money="counterMoney"
                :items="presets"
                :is-loading="isLoading"
                :total="getterMainClientInfo.order_total"
                name="All presets"
                @input="setPreset"
            />
        </div>

        <div class="orders__list">
            <radius-loader v-if="isLoading" />
            <div class="orders__list-wrapper">
                <div class="wrapper">
                    <div class="orders__list-order-cart-button">
                        <order-cart-button />
                    </div>
                    <order-card
                        v-for="(order, index) in orders"
                        :key="order.orderid"
                        :unread-messages-count="order.counters.unread_messages_count"
                        :unread-ticket-messages-count="order.counters.unread_ticket_messages_count"
                        :unread-files-main-count="order.counters.unread_files_main_count"
                        :unread-files-add-count="order.counters.unread_files_add_count"
                        :order="order"
                        :index="index"
                        :page="searchObject.page"
                        mode="list"
                        @show:modal="showModal"
                    />
                </div>
            </div>
            <custom-button
                v-if="searchObject.page < lastPage"
                :loading="isLoading"
                default
                class="btn-base_colored sort-button btn-page-location"
                @on-btn-click="loadMore"
            >
                Load more
            </custom-button>
        </div>

        <no-result
            v-if="!isLoading && orders.length === 0"
            type="orders"
        />

        <!-- <invite-widget /> -->

        <component
            :is="modal.modal"
            v-if="showPopup"
            :data="modal.data"
            :order="current_order"
            @confirmModal="confirmModal"
            @closeModal="closeModal"
        />
    </section>
</template>

<script>
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'
import filtersMixin from '@/mixins/filtersMixin.js'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import Api from '@/helpers/api/index.js'
import { eventBus } from '@/helpers/event-bus'
import OrderFilter from '@/components/account/orders/OrderFilter.vue'
import PresetsFilter from '@/components/common/PresetsFilter'
import PresetsFilterSelect from '@/components/common/PresetsFilterSelect'

import OrderCard from '@/components/account/Cards/OrderCard/OrderCard.vue'
import RadiusLoader from '@/components/common/RadiusLoader'
import NoResult from '@/components/common/NoResult'
import PresetLoader from '@/components/common/PresetLoader';

// Order Modals
import ConfirmModal from '@/components/account/orders/Modals/ConfirmModal';
import FeedbackModal from '@/components/account/orders/Modals/FeedbackModal';
import DeadlineModal from '@/components/account/orders/Modals/DeadlineModal';
import ExtraPagesModal from '@/components/account/orders/Modals/ExtraPagesModal';
import RevisionModal from '@/components/account/orders/Modals/RevisionModal';
// import LoadingOverlay from '@/components/LoadingOverlay.vue'
// import InviteWidget from '@/components/friends/InviteWidget.vue'
import OrderCartButton from '@/components/Header/OrderCartButton.vue'

// Vuex
import {
    RESET,
    SET_PRESET
} from '@/store/modules/order/mutation-types'

const {
    mapMutations: mapOrderMutations
} = createNamespacedHelpers('order')

export default {
    components: {
        OrderCard,
        RadiusLoader,
        OrderFilter,
        PresetsFilter,
        NoResult,
        // modals
        ConfirmModal,
        FeedbackModal,
        DeadlineModal,
        ExtraPagesModal,
        RevisionModal,
        PresetLoader,
        // InviteWidget,
        PresetsFilterSelect,
        // LoadingOverlay,
        OrderCartButton
    },
    mixins: [
        filtersMixin
    ],
    metaInfo: {
        title: 'Orders'
    },
    data() {
        return {
            isFirstLoad: true,
            searchObject: {
                page: 1,
                per_page: 10
            },
            // modals
            showPopup: false,
            current_order: null,
            modal: {},
            // new logic
            isLoadingPresets: true,
            isLoading: true,
            presets: [],
            counter: null,
            counterMoney: null,
            orders: []
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainClientInfo'
        ]),
        lastPage() {
            return Math.ceil(this.counter / this.searchObject.per_page)
        },
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        }
    },
    async activated() {
        if (this.isFirstLoad) {
            this.isFirstLoad = false
        } else {
            await this.updateList()
        }
    },
    async created() {
        this.searchObject = {
            ...this.searchObject,
            ...this.queryObject,
            page: 1
        }
        await Promise.all([this.getPresets(), this.getOrderList()])
        if (this.searchObject['preset'] === undefined) {
            await this.setActivePreset(this.getPresetData(null))
        } else {
            await this.setActivePreset(this.getPresetData(this.searchObject.preset))
        }
    },
    methods: {
        ...mapOrderMutations({
            resetOrder: RESET,
            setActivePreset: SET_PRESET
        }),
        getPresetData(value) {
            const data = this.presets.find((element) => element.value === value)
            if (value === null) {
                return { title: 'All', count: null }
            }
            return { title: data.title, count: data.counters.count }
        },
        async getPresets() {
            try {
                this.isLoadingPresets = true
                const { data } = await Api.get('/api/order/list-presets')
                this.presets = data
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoadingPresets = false
            }
        },
        async getOrderList(withCout = true) {
            try {
                this.isLoading = true
                let requests = []
                if (this.searchObject.preset) {
                    requests = [...requests, Api.get('/api/order/list-by-preset', removeEmptyParamsFromRequest({ mode: 'list', ...this.searchObject }))]
                    if (withCout) {
                        requests = [...requests, Api.get('/api/order/list-by-preset', removeEmptyParamsFromRequest({ mode: 'count', ...this.searchObject }))]
                    }
                } else {
                    requests = [...requests, Api.get('/api/order/list-by-params', removeEmptyParamsFromRequest({ mode: 'list', ...this.searchObject }))]
                    if (withCout) {
                        requests = [...requests, Api.get('/api/order/list-by-params', removeEmptyParamsFromRequest({ mode: 'count', ...this.searchObject }))]
                    }
                }
                const responses = await Promise.all(requests)
                const { data } = responses[0]
                if (responses[1]) {
                    const { data: totals } = responses[1]
                    this.counter = totals.count
                    this.counterMoney = totals.total
                }
                this.orders = [...this.orders, ...data.data]
                if (JSON.stringify(this.searchObject) !== JSON.stringify(this.queryObject)) {
                    this.$router.replace({ query: removeEmptyParamsFromRequest({ ...this.searchObject }) })
                }
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        },
        async setPreset(preset) {
            this.searchObject = {
                ...this.searchObject,
                preset,
                page: 1
            }
            this.orders = []
            await this.getOrderList()
            await this.setActivePreset(this.getPresetData(preset))
        },
        async loadMore() {
            this.searchObject.page += 1
            await this.getOrderList(false)
        },
        async onFilterOrder(payload) {
            this.searchObject = { ...this.searchObject, ...payload, page: 1 }
            this.orders = []
            await this.getOrderList()
        },
        // MODALS
        showModal(payload) {
            this.current_order = this.orders.find((item) => item.orderid === payload.orderid)
            this.showPopup = true
            this.modal = payload
        },
        closeModal() {
            this.showPopup = false
            this.modal = {}
            this.current_order = {}
        },
        async deleteUnpaidOrder(id) {
            try {
                await Api.delete('/api/order/action/delete', { id })
                eventBus.$emit('showSnackBar', `Order ${id} has been deleted!`, 'success');
                this.orders = this.orders.filter((item) => item.orderid !== id)
                this.$store.commit('client/UPDATE_CLIENT_DATA', {
                    statistics: {
                        ...this.$store.state.client.client_data.statistics,
                        orders_total: this.orders.length
                    }
                })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async feedbackOrder(payload, isEdit) {
            try {
                if (isEdit) {
                    await Api.put('/api/testimonial/auth/update', payload)
                } else {
                    await Api.post('/api/testimonial/auth/create', payload)
                }
                const findIndex = this.orders.findIndex((item) => item.orderid === payload.orderid)
                this.orders.splice(findIndex, 1, {
                    ...this.orders[findIndex],
                    testimonials: [
                        {
                            ...this.orders[findIndex].testimonials[0],
                            ...payload,
                            writer: {
                                name: this.orders[findIndex].writer.nickname,
                                sw_id: this.orders[findIndex].sw_id
                            }
                        }
                    ]
                })
                this.$router.push({ name: 'successful_rate' })
                // eventBus.$emit('showSnackBar', 'Your feedback has been submited. Thank you!', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        },
        async revisionOrder(payload) {
            try {
                await Api.post('/api/order-message/auth/create-revision', payload)
                const findIndex = this.orders.findIndex((item) => item.orderid === payload.orderid)
                this.orders.splice(findIndex, 1, {
                    ...this.orders[findIndex],
                    status: 'REVISION'
                })
                await this.updateList()
                eventBus.$emit('showSnackBar', 'Your revision request has been submitted. Please wait for the status update by email', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        },
        async deadlineOrder(payload) {
            try {
                await Api.put('/api/order/action/extend-deadline', payload)
                const findIndex = this.orders.findIndex((item) => item.orderid === payload.id)
                this.orders.splice(findIndex, 1, {
                    ...this.orders[findIndex],
                    due_at: payload.datetime
                })
                eventBus.$emit('showSnackBar', 'The deadline has been extended.', 'success');
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        },
        async confirmModal(payload, isEdit) {
            switch (this.modal.action) {
            case 'delete':
                await this.deleteUnpaidOrder(this.modal.orderid)
                break
            case 'feedback':
                await this.feedbackOrder(payload, isEdit)
                break
            case 'revision':
                await this.revisionOrder(payload)
                break
            case 'deadline':
                await this.deadlineOrder(payload)
                break
            default:
            }
            this.closeModal()
        },
        async syncOrderListKeepAlive(paylaod) {
            let resposes = null
            if (this.searchObject.preset) {
                resposes = await Api.get('/api/order/list-by-preset', { ...paylaod, mode: 'list' })
            } else {
                resposes = await Api.get('/api/order/list-by-params', { ...paylaod, mode: 'list' })
            }
            return resposes.data.data
        },
        async updateList() {
            const requests = []
            const pageLoaded = this.searchObject.page
            for (let index = 1; index <= pageLoaded; index += 1) {
                requests.push(this.syncOrderListKeepAlive(
                    removeEmptyParamsFromRequest({ ...this.searchObject, page: index, per_page: this.searchObject.per_page || 10 })
                ))
            }
            const resp = await Promise.all(requests)
            this.orders = this.orders.map((order) => {
                const find = resp.flat().find((item) => item.orderid === order.orderid)
                if (!find) {
                    return false
                }
                order = {
                    ...order,
                    ...find
                }
                return order
            }).filter((a) => Boolean(a))
            if (this.orders.length === 0) {
                this.searchObject = {
                    ...this.searchObject,
                    status: '',
                    page: 1,
                    from: '',
                    to: '',
                    search_by: '',
                    search_for: ''
                }
                await this.getOrders()
            }
        }
    }
}

</script>

<style lang="scss">
    .orders__list {
        .background-for-radius-loader {
            position: absolute;
            height: calc(100% + 10px);
        }
    }
    .wrapper--order-filter{
        margin-bottom: 20px;
        .count {
            padding: 10px;
            padding-right: 0;
            border-radius: 5px;
            font-weight: 600;
            color: $main-color;
            font-size: 24px;
            width: 180px;
            text-align: center;
        }
        @include media992max {
            padding: 0;
        }
    }

    .orders__list-wrapper{
        position: relative;
    }

    .orders__list-order-cart-button{
        display: none;
        @include media480max {
            display:flex;
            margin: 0px auto 15px;
            width: 100%;
        }
    }
</style>
